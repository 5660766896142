<template>
  <div class="task-list m-t">
    <u-table
        ref="table"
        color="primary"
        :data="data"
        :columns="columns"
        :visible-columns="visibledColumns"
        row-key="id"
        :pagination.sync="options.serverPagination"
        selection="multiple"
        :loading="options.loading"
        :selected.sync="options.selected"
        class="task-table table-v2"
        :rows-per-page-options="[20, 50, 100]"
        :hide-no-results-label="true"
        @request="request"
    >
      <u-tr slot="header" slot-scope="props">
        <u-th auto-width v-if="mode !== 'cliente'">
          <u-checkbox
              v-model="props.selected"
              :indeterminate="props.partialSelected"
              size="xs"
          />
        </u-th>
        <u-th v-for="col in props.cols" :key="col.name" :props="props" :class="'col-' + col.name">
          <template v-if="col.name === 'dataPrazo'">
            {{ col.label }} <i @click.stop.prevent="listOptions" class="m-l fal fa-cog inline text-grey-8"/>
          </template>
          <template v-else>{{col.label}}</template>
        </u-th>
      </u-tr>

      <u-tr slot="body" slot-scope="props"
            :props="props" :class="[{'u-table-item-destac': props.row.isToday}, trClass(props.row)]">
        <u-td class="pos-rlt" auto-width v-if="mode !== 'cliente'">
          <u-checkbox color="primary" v-model="props.selected" size="xs"/>
        </u-td>
        <u-td style="max-width: 50px; min-width: 50px; width: 50px" key="queue" :props="props">
          {{ props.row.queuePosition }}
        </u-td>
        <u-td style="max-width: 50px; min-width: 50px; width: 50px" key="id" :props="props" class="col-id">
          {{ props.row.id }}
        </u-td>
        <u-td class="td-limit l-200 col-tipo" key="tipo" :props="props">
          {{ props.row.type ? props.row.type.name : '-' }}
        </u-td>
        <u-td :title="props.row.resumo" class="td-limit l-400 col-titulo" key="titulo"
              :props="props">
          <router-link v-if="isGerencia && mode === 'cliente'" tag="a" :to="{
        name: 'suporte.cliente.tarefa',
        params: {
          id: props.row.id,
        }
      }">{{ props.row.description }}</router-link>
          <router-link v-else-if="isGerencia" tag="a" :to="{
        name: 'console.tarefa',
        params: {
          id: props.row.id,
        }
      }">{{ props.row.description }}</router-link>
          <router-link v-else-if="project" tag="a" target="_blank" :to="{
        name: 'console.tarefa',
        params: {
          id: props.row.id
        }
      }">{{ props.row.description }}</router-link>
          <router-link v-else-if="$route.name === 'suporte.tarefas.fila'" tag="a" :to="{
        name: 'suporte.tarefas.fila.tarefa',
        params: {
          tarefa: props.row.id
        }
      }">{{ props.row.description }}</router-link>
          <router-link v-else tag="a" :to="{
        name: 'suporte.tarefa',
        params: {
          tarefa: props.row.id
        }
      }">{{ props.row.description }}</router-link>
        </u-td>
        <u-td key="relator" :props="props">
          <span v-if="props.row.author">{{props.row.author.name}}</span>
        </u-td>
        <u-td class="td-limit" key="dataRegistro" :props="props">
          <div v-if="props.row.createdAt" style="cursor: default">
            {{ props.row.createdAt.date|formatDate('dd/') }}{{
              props.row.createdAt.date|formatDate('LLL')
            }}{{ props.row.createdAt.date|formatDate('/yy') }}
            <u-tooltip content-class="bg-indigo font-11" :offset="[15, 15]">
              {{ props.row.createdAt.date|formatDate('dd/MM/yyyy HH:ii:ss') }}
            </u-tooltip>
          </div>
          <div v-else>Nunca</div>
        </u-td>
        <u-td class="td-limit" key="cliente" :props="props">
          <div v-if="props.row.cliente">
            {{ props.row.cliente.name }}
          </div>
          <div v-else>-</div>
        </u-td>
        <u-td key="projeto" :props="props">
          <div v-if="props.row.project">{{props.row.project.name}}</div>
        </u-td>
        <u-td class="td-limit today" style="max-width: 80px; width: 80px" key="participantes" :props="props">
          <div v-if="props.row.cache && props.row.cache.participants">
            <task-group-persons :persons="props.row.cache.participants"/>
          </div>
          <span v-else></span>
        </u-td>
        <u-td class="td-limit today" key="progress" :props="props">
          <div class="flex items-center no-wrap">
            <u-progress class="progress-sgrp m-r"
                        :percentage="props.row.progress"
                        color="positive" height="6px" style="border-radius: 5px; max-width: 70%"></u-progress>
            {{ props.row.progress }}%
          </div>
        </u-td>
        <u-td class="text-uppercase col-status" key="status" :props="props">
          <div :class="'task-status-' + props.row.status.code">{{
              props.row.status ? props.row.status.name : '-'
            }}
          </div>
        </u-td>
        <u-td class="td-limit today col-dataPrazo" key="dataPrazo" :props="props">
          <div v-if="props.row.deadline">
            <span class="dl-dt">{{ props.row.deadline|formatDate('dd/MM/yyyy') }}</span> <span class="dl-hr">{{ props.row.deadline|formatDate('HH:mm') }}</span>
            <span class="m-l-xs trask-atraso-lbl" v-if="isOpen(props.row) && atraso(props.row.deadline) > 0"><i
                class="fas fa-exclamation-triangle m-r-xs"></i> {{ atraso(props.row.deadline) }} dia{{ atraso(props.row.deadline) > 1 ? 's' : '' }}</span>
          </div>
          <span v-else>Sem previsão</span>
        </u-td>
        <!--        <u-td class="text-center" key="options" :props="props">
                </u-td>-->
      </u-tr>
    </u-table>
  </div>
</template>

<script>
import {listName, listStorage} from "./config/list"
import {listName as listNameCliente, listStorage as listStorageCliente} from "./config/listCliente"
import listOptions from "@/reuse/list/listOptions"
import {LocalStorage, UCheckbox, UTable, UTd, UTh, UTr, UProgress, UTooltip} from "uloc-vue"
import ECol from "components/layout/components/Col"
import ERow from "components/layout/components/Row"
import {EBtnTableOptions} from "@/plugins/uloc-erp"
import MenuOptions from "components/layout/context-menu/context-window-options"
import ListMenu from "@/reuse/list/ListMenu"
import TaskGroupPersons from "components/sltarefas/components/others/TaskGroupPersons"
import {differenceInHours, parseISO} from "date-fns"

let _listName = listName
let _listStorage = listStorage

export default {
  name: "TaskList",
  props: {
    data: {
      type: Array
    },
    options: {
      type: Object
    },
    request: {
      type: Function
    },
    optionColumn: {
      type: Boolean,
      default: true
    },
    colorize: {
      type: Boolean,
      default: false
    },
    project: {
      required: false
    },
    mode: {
      type: String,
      required: false
    },
    isGerencia: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    if (this.mode === 'cliente') {
      _listName = listNameCliente
      this.listStorage = _listStorage = listStorageCliente
    }
    const columns = _listStorage.map(o => {
      if (this.project && o.name === 'projeto') {
        // Desativa coluna de projeto
        o.active = false
      }
      return {
        ...o,
        field: o.name,
        align: 'left',
      }
    })
    this.optionColumn && columns.concat([
      {
        name: 'options',
        required: true,
        label: '',
        field: 'options',
        sortable: false
      }
    ])
    return {
      listName: _listName,
      listStorage: this.listStorage,
      columns: columns
    }
  },
  beforeCreate() {
    this.listStorage = _listStorage
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  methods: {
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      console.log('Ok', newConfig)
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    },
    isOpen (task) {
      return task.status.type === 'opened'
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    abrir(t) {
      if (this.isGerencia) {
        this.$router.push({
          name: 'console.tarefa',
          params: {
            id: t.id
          }
        })
        return
      }
      if (t.project) {
        this.$router.push({
          name: 'suporte.projeto.tarefa',
          params: {
            id: t.project.id,
            tarefa: t.id
          }
        })
      } else {
        this.$router.push({
          name: 'suporte.tarefa',
          params: {
            tarefa: t.id
          }
        })
      }
    },
    trClass (task) {
      const css = []
      if (this.colorize) {
        if (task.deadline && this.atraso(task.deadline) > 0) {
          css.push('sla-out')
        }
      }
      return css
    }
  },
  components: {
    TaskGroupPersons,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    //ECol,
    //ERow,
    // EBtnTableOptions,
    // MenuOptions,
    UProgress,
    UTooltip
    // ListMenu
  }
}
</script>
